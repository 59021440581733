import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import DashboardMenuItem from "../components/DashboardMenuItem";
import { HiOutlinePresentationChartLine, HiOutlineUsers } from "react-icons/hi";
import { BiSolidCar } from "react-icons/bi";
import { GiPadlock } from "react-icons/gi";
import { TbLogout } from "react-icons/tb";
import { BsCoin, BsList, BsTelephone } from "react-icons/bs";
import { AiOutlineTransaction } from "react-icons/ai";
import { SiExpensify } from "react-icons/si";
import { LOG_OUT, SET_LOADING } from "../redux/type";
import { ToastContainer } from "react-toastify";
import { getInfoByToken } from "../redux/actions/authAction";
import { IoDocumentTextOutline } from "react-icons/io5";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { IoIosHome } from "react-icons/io";

const MyAccountLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector((state) => state.global.isLoading);
  const userdata = useSelector((state) => state.auth.user);
  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setIsBurgerMenuVisible(!isBurgerMenuVisible);
  };

  const Logout = () => {
    dispatch({ type: LOG_OUT, payload: null });
  };

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: true });
    getInfoByToken(dispatch, true, navigate);
  }, []); // Empty dependency array means this effect runs once, similar to componentDidMount
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const MenuContent = () => {
    return (
      <div className="h-full w-[250px] bg-[#024273] p-6 overflow-auto">
        <div
          className={`${
            isLoading ? "fixed" : "hidden"
          } transition-all top-0 flex items-center justify-center w-screen h-screen bg-white/90 z-[9999]`}
        >
          <span className="loader"></span>
        </div>
        <Link to="/" className="w-full">
          <img
            src="image/dashboard_logo.png"
            alt=""
            className="w-3/5 m-auto mt-6"
          />
        </Link>
        <div className="w-full mt-20">
          <p className="text-white font-text">My Account</p>
          <div className="flex flex-col w-full gap-1 mt-4">
            <DashboardMenuItem
              title="Home"
              toggleDrawer={toggleDrawer}
              icon={<IoIosHome />}
              isactive={location.pathname === "/"}
              href="/"
            />
            <DashboardMenuItem
              title="Dashboard"
              toggleDrawer={toggleDrawer}
              icon={<HiOutlinePresentationChartLine />}
              isactive={location.pathname === "/dashboard"}
              href="/dashboard"
            />

            <DashboardMenuItem
              toggleDrawer={toggleDrawer}
              title="Edit Profile"
              href="/profile"
              isactive={location.pathname === "/profile"}
              icon={<HiOutlineUsers />}
            />
            <DashboardMenuItem
              toggleDrawer={toggleDrawer}
              title="Change Password"
              href="/change_password"
              isactive={location.pathname === "/change_password"}
              icon={<GiPadlock />}
            />
            {userdata?.user_type !== "C" ? (
              <>
                <DashboardMenuItem
                  toggleDrawer={toggleDrawer}
                  title="Manage Vehicles"
                  icon={<BiSolidCar />}
                  isactive={location.pathname === "/manage_vehicles"}
                  href="/manage_vehicles"
                />
                <DashboardMenuItem
                  toggleDrawer={toggleDrawer}
                  title="Payment Details"
                  icon={<BsCoin />}
                  isactive={location.pathname === "/payment_details"}
                  href="/payment_details"
                />
              </>
            ) : (
              <DashboardMenuItem
                toggleDrawer={toggleDrawer}
                title="My licences"
                href="/my-licences"
                isactive={location.pathname === "/my-licences"}
                icon={<IoDocumentTextOutline />}
              />
            )}
          </div>
        </div>
        {userdata?.user_type === "C" && (
          <div className="w-full mt-2 py-6 border-t border-[rgba(255,255,255,0.4)]">
            <p className="text-white font-text">Booking</p>
            <div className="flex flex-col w-full gap-1 mt-4">
              <DashboardMenuItem
                toggleDrawer={toggleDrawer}
                title="My Booking"
                icon={<HiOutlinePresentationChartLine />}
                href="/mybooking"
                isactive={location.pathname === "/mybooking"}
              />
            </div>
          </div>
        )}
        {userdata?.user_type !== "C" && (
          <div className="w-full mt-2 py-6 border-t border-[rgba(255,255,255,0.4)]">
            <p className="text-white font-text">Transactions</p>
            <div className="flex flex-col w-full gap-1 mt-2">
              <DashboardMenuItem
                toggleDrawer={toggleDrawer}
                title="Income"
                icon={<AiOutlineTransaction />}
                href="/income"
                isactive={location.pathname === "/income"}
              />
              <DashboardMenuItem
                toggleDrawer={toggleDrawer}
                title="Expenses"
                icon={<SiExpensify />}
                href="/expenses"
                isactive={location.pathname === "/expenses"}
              />
            </div>
          </div>
        )}
        <div className="w-full mt-2 py-6 border-t border-[rgba(255,255,255,0.4)]">
          <div className="flex flex-col w-full gap-1">
            <DashboardMenuItem
              toggleDrawer={toggleDrawer}
              title="Logout"
              icon={<TbLogout />}
              href="/"
              onClick={Logout}
              isactive={true}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex w-screen h-screen">
      <div className={`h-full hidden lg:block`}>
        <MenuContent />
      </div>
      <div className="h-full bg-[#f7f7f7] w-[100%]">
        <div className="h-[70px] bg-white w-full px-5 lg:px-12 flex justify-between lg:justify-end items-center">
          <div className="lg:hidden px">
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction="left"
              className="bla"
            >
              <MenuContent />
            </Drawer>
            <span
              className="block text-3xl font-bold text-black cursor-pointer lg:hidden"
              onClick={toggleDrawer}
            >
              <BsList />
            </span>
          </div>
          <p className={"text-2xl text-black text-right"}>{userdata?.name}</p>
        </div>
        <div className=" h-[calc(100%-70px)] px-4 sm:px-12 overflow-auto w-full">
          <div className="w-full min-h-[calc(100%-70px)]">
            <div className="overflow-x-auto max-w-full">
              <Outlet />
            </div>
          </div>
          <div className="h-[100px] sm:h-[70px] w-full flex flex-col items-center sm:flex-row sm:justify-between">
            <p className="mb-5 mt-5 sm:mt-0 sm:mb-0">
              &copy; 2025 YOKS-Car-Rental
            </p>
            <div className="flex gap-8 pb-5 sm:pb-0">
              <p>Terms</p>
              <p>Privacy policy</p>
              <p>Legal notice</p>
              <p>Accessibility</p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MyAccountLayout;
