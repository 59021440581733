import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { getCookie } from "../../../utils/constants";
import {
  cancelBookingAPI,
  cancelListAPI,
  getDashboardAPI,
} from "../../../redux/actions/myaccountAction";
import { useDispatch, useSelector } from "react-redux";
import RButton from "../../../components/RButton";
import CancelModal from "./CancelModal";
import ChargePayment from "../../Booking/ChargePayment";
import { paymentAPI } from "../../../redux/actions/bookingAction";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

const BookingCard = () => {
  const [visible, setVisible] = useState(false);
  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderid",
      width: 120,
      render: (text, record) => (
        <Link to={`/order-invoice/${record.rawItem?.id}`}>{text}</Link>
      ),
    },
    {
      title: "Payment ID",
      dataIndex: "payment_id",
    },
    {
      title: "Date Time",
      dataIndex: "date_time",
    },
    {
      title: "From To",
      dataIndex: "fromto",
    },
    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "Status",
      dataIndex: "bookingstatus",
      render: (text) => {
        switch (text) {
          case 0:
            return <Tag>Completed</Tag>;
          case 1:
            return <Tag>In Progress</Tag>;
          case 2:
            return <Tag>Success</Tag>;
          case 3:
            return <Tag>Rejected</Tag>;
          default:
            return <>{text}</>;
        }
      },
    },
    {
      title: "Payment",
      // dataIndex: "payment",
      render: (text) => {
        switch (text.payment) {
          case 0:
            return (
              <div>
                {text.bookingstatus === "Upcoming" ? (
                  <RButton
                    isradius={true}
                    style={{
                      backgroundColor: "#D9841DFF",
                      color: "#fff",
                    }}
                    onClick={() => {
                      beforePayment(text.rawItem);
                    }}
                  >
                    <span className="flex w-full justify-center items-center gap-2 px-6">
                      Payment
                    </span>
                  </RButton>
                ) : (
                  <>Can't pay {text.bookingstatus} booking</>
                )}
              </div>
            );
          case 1:
            return <Tag>Paid</Tag>;
          default:
            return <>{text.payment}</>;
        }
      },
    },
    {
      title: "Action",
      render: (text) => {
        return (
          <div>
            {text.bookingstatus === "Upcoming" ? (
              <>
                {checkingIsCancelAble(text.rawItem) ? (
                  <RButton
                    isradius={true}
                    style={{
                      backgroundColor: "#c82333",
                      color: "#fff",
                    }}
                    onClick={() => {
                      if (checkingIsCancelAble(text.rawItem)) {
                        handleCancel(text.rawItem);
                      } else {
                        toast.error("Can't cancel this booking");
                      }
                    }}
                  >
                    <span className="flex w-full justify-center items-center gap-2 px-10">
                      Cancel
                    </span>
                  </RButton>
                ) : (
                  <p>Can't cancel this booking</p>
                )}
              </>
            ) : (
              <>{text.bookingstatus}</>
            )}
          </div>
        );
      },
    },
  ];
  const checkingIsCancelAble = (item) => {
    const now = moment();
    const pickup = moment(item?.pickup, "YYYY-MM-DD HH:mm:ss");
    const diff = pickup.diff(now, "minutes");
    return diff >= 120;
  };
  const [dashboardData, setDashboardData] = useState({});
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [cancelList, setCancelList] = useState([]);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [price, setPrice] = useState(0);
  const [charge, setCharge] = useState(false);
  const [beforeDone, setBeforeDone] = useState(false);
  const [bookingResponse, setBookingResponse] = useState(null);
  const [onPaymentSaved, setOnPaymentSaved] = useState(false);
  const getMyBookingData = () => {
    const bookingData = [];
    dashboardData?.bookings?.forEach((item, index) => {
      bookingData.push({
        key: index,
        orderid: item.booking_id,
        date_time: item?.meta_data?.journey_date,
        fromto: item.dest_addr,
        total: item.tax_total,
        bookingstatus: item.ride_status,
        payment_id: item.payment_data?.transaction_id,
        payment: item.payment,
        rawItem: item,
      });
    });
    // make booking data decending key
    bookingData.sort((a, b) => {
      return b.key - a.key;
    });
    return bookingData;
  };
  const getDashboard = () => {
    let postData = {
      api_token: getCookie("token"),
      type: "User",
    };
    getDashboardAPI(dispatch, postData)
      .then((res) => {
        console.log(res);
        setDashboardData(res);
      })
      .catch((error) => {
        console.log(error);
      });
    cancelListAPI(dispatch, postData)
      .then((res) => {
        console.log(res);
        setCancelList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getDashboard();
  }, []);
  useEffect(() => {
    if (onPaymentSaved) {
      getDashboard();
      setOnPaymentSaved(false);
    }
  }, [onPaymentSaved]);
  const handleCancel = (item) => {
    console.log(item);
    setSelectedItem(item);
    setVisible(true);
  };
  const onCancelPress = (id, reason) => {
    console.log(id, reason);
    let postData = {
      api_token: getCookie("token"),
      booking_id: id,
      reason: reason,
    };
    cancelBookingAPI(dispatch, postData)
      .then((res) => {
        console.log(res);
        setVisible(false);
        if (res.status) {
          toast.success("Successfully cancelled");
          getDashboard();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        setVisible(false);
      });
  };
  const convertToDecimal = (value) => {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0"
    )
      return 0;
    if (typeof value === "string") {
      value = parseInt(value, 2);
    } else if (typeof value === "number") {
      // return with 2 decimal
      value = parseFloat(value.toFixed(2));
    }
    return value;
  };
  const onPaymentSuccess = (r) => {
    console.log(r);
    setCharge(false);
    setBeforeDone(false);
    if (r !== null && r !== undefined) {
      const postData = {
        api_token: getCookie("token"),
        booking_id: bookingResponse.id,
        reference: r.reference,
      };
      console.log(postData);
      paymentAPI(dispatch, postData, navigate, null, true, setOnPaymentSaved);
    }
  };
  const priceCalculate = async (valueinUSD) => {
    try {
      const ghcGet = await axios.get(
        "https://latest.currency-api.pages.dev/v1/currencies/usd.json"
      );

      const ghcRate = convertToDecimal(ghcGet.data?.usd?.ghs ?? 0);
      if (ghcRate === 0) throw new Error("Invalid exchange rate received");

      const convertedValue = valueinUSD * ghcRate;
      // convertedValue.toFixed(2);

      return convertToDecimal(convertedValue);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      return 0;
    }
  };
  const beforePayment = async (order) => {
    setBookingResponse(order);
    const calculatedPrice = await priceCalculate(order.tax_total);
    setPrice(calculatedPrice);
    console.log("Price Before Payment is", calculatedPrice);
    // setPrice(order.tax_total);
    setBeforeDone(true);
    console.log(order);
  };
  // useEffect(() => {
  //   if (beforeDone && price !== 0) {
  //     setCharge(true);
  //     setBeforeDone(false);
  //   }
  // }, [beforeDone, price]);

  useEffect(() => {
    if (beforeDone) {
      // check price and booking response object
      if (
        price === 0 ||
        bookingResponse === null ||
        bookingResponse === undefined ||
        user === null ||
        user === undefined
      ) {
        // setBeforeDone(false)
      } else {
        setCharge(true);
        setBeforeDone(false);
      }
    }
  }, [beforeDone, bookingResponse, price, user]);
  return (
    <div className="w-full">
      <CancelModal
        visible={visible}
        setVisible={setVisible}
        item={selectedItem}
        onCancelPress={onCancelPress}
        cancelList={cancelList}
      />
      <div className="overflow-x-auto max-w-full">
        <Table columns={columns} dataSource={getMyBookingData()} />
      </div>
      <ChargePayment
        email={user?.email}
        price={convertToDecimal(price)}
        onPaymentSuccess={onPaymentSuccess}
        charge={charge}
        showButton={false}
      />
    </div>
  );
};

export default BookingCard;
