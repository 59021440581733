import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, Modal, TextField, Typography } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import RButton from "../../components/RButton";
import Select from "react-select";
import styled from "styled-components";
import citydata from "../../utils/citylist.json";
import { BsArrowUpRight } from "react-icons/bs";
import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "../../utils/config";
import { SET_BOOKING_DETAILS } from "../../redux/type";
import { setKey, fromPlaceId } from "react-geocode";
import {
  gettingDistanceCalculate,
  gettingSettings,
} from "../../redux/actions/bookingAction";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import LicenceComponent from "../MyAccount/Licence/LicenceComponent";
import LicenceDetails from "../MyAccount/Licence/LicenceDetails";
import axios from "axios";

const Details = ({ onStep }) => {
  const book_state = useSelector((state) => state.book);
  const vehicle = useSelector((state) => state.book?.vehicle_details);
  const booking_details = useSelector((state) => state.book?.booking_details);
  const cityList = citydata;
  const [airportPickup, setAirportPickup] = useState(false);
  const [outsideDestination, setOutsideDestination] = useState(false);
  const [outsideDestinationName, setOutsideDestinationName] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [flightNo, setFlightNo] = useState(null);
  const [travelers, setTravelers] = useState(1);
  const dispatch = useDispatch();
  const [outsideCost, setOutsideCost] = useState(0);
  const [outsideCostGHC, setOutsideCostGHC] = useState(0);
  const license = useRef(null);
  const passport = useRef(null);
  const [licenseFile, setLicenseFile] = useState(null);
  const [licenseFileBase64, setLicenseFileBase64] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [passportFileBase64, setPassportFileBase64] = useState(null);
  const [testify, setTestify] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmAndContinue = () => {
    setShowConfirmation(false); // Close confirmation
    continueClicked();
  };

  const MSelect = styled(Select)`
    height: 53px;
    width: 100%;
    & > div:first-of-type {
      height: 100%;
      width: 100%;
    }
  `;
  useEffect(() => {
    console.log(booking_details);
    if (booking_details?.airportPickup)
      setAirportPickup(booking_details?.airportPickup);
    if (booking_details?.outsideDestination)
      setOutsideDestination(booking_details?.outsideDestination);
    if (booking_details?.outsideDestinationName)
      setOutsideDestinationName(booking_details?.outsideDestinationName);
    if (booking_details?.arrivalDate)
      setArrivalDate(booking_details?.arrivalDate);
    if (booking_details?.arrivalTime)
      setArrivalTime(booking_details?.arrivalTime);
    if (booking_details?.flightNo) setFlightNo(booking_details?.flightNo);
    if (booking_details?.travelers) setTravelers(booking_details?.travelers);
    if (booking_details?.outsideCost) {
      setOutsideCost(booking_details?.outsideCost);
    }

    if (booking_details?.testify) setTestify(booking_details?.testify);
  }, [booking_details]);
  const continueClicked = (fileData = {}) => {
    const bookingDetails = {
      airportPickup,
      outsideDestination,
      outsideDestinationName,
      arrivalDate,
      arrivalTime,
      flightNo,
      travelers,
      outsideCost,
      ...fileData,
      testify,
    };
    console.log(bookingDetails);
    dispatch({ type: SET_BOOKING_DETAILS, payload: bookingDetails });
    onStep(3);
  };

  const checkFileSelected = () => {
    if (book_state?.serviceType.value === "self-drive") {
      if (licenseFile === null || licenseFile === undefined) {
        toast.error("Please upload license file");
        return false;
      }
      if (passportFile === null || passportFile === undefined) {
        toast.error("Please upload passport or ID Card Upload");
        return false;
      }
      return true;
    }
    return true;
  };

  const convertBothFileToBase64 = async () => {
    const licenseFileBase64 = await getBase64(licenseFile);
    const passportFileBase64 = await getBase64(passportFile);
    return { licenseFileBase64, passportFileBase64 };
  };
  const checkAirportPickup = () => {
    if (airportPickup) {
      if (arrivalDate === null || arrivalDate === undefined) {
        toast.error("Please select arrival date");
        return false;
      }
      if (arrivalTime === null || arrivalTime === undefined) {
        toast.error("Please select arrival time");
        return false;
      }
      if (flightNo === null || flightNo === undefined) {
        toast.error("Please enter flight no");
        return false;
      }
    }
    return true;
  };
  const checkOutsideDestination = () => {
    if (outsideDestination) {
      if (
        outsideDestinationName === null ||
        outsideDestinationName === undefined
      ) {
        toast.error("Please enter outside destination");
        return false;
      }
    }
    return true;
  };
  const checkIfTestify = () => {
    if (book_state?.serviceType.value === "self-drive") {
      if (!testify) {
        toast.error("Please check the testify");
        return false;
      }
    }
    return true;
  };
  const beforeContinue = () => {
    console.log(book_state);
    //   check airport pickup
    if (!checkAirportPickup()) return;
    if (!checkOutsideDestination()) return;
    if (!checkIfTestify()) return;

    // stopped for purpose
    // if (!checkFileSelected()) return;
    // if (book_state?.serviceType.value === "self-drive") {
    // //   first convert the file to base64
    //     convertBothFileToBase64().then(r => {
    //         continueClicked(r);
    //     }).catch(e => {
    //         toast("Error in uploading file")
    //     })
    // } else {
    //     continueClicked();
    // }
    // continueClicked();
    setShowConfirmation(true);
  };
  const convertToDecimal = (value) => {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0"
    )
      return 0;
    if (typeof value === "string") {
      value = parseInt(value, 2);
    } else if (typeof value === "number") {
      // return with 2 decimal
      value = parseFloat(value.toFixed(2));
    }
    return value;
  };
  useEffect(() => {
    const data = {
      origin: book_state?.pickupLocation.formatted_address,
      destination: outsideDestinationName?.formatted_address,
    };
    console.log(vehicle);
    if (
      outsideDestinationName?.formatted_address === null ||
      outsideDestinationName?.formatted_address === undefined
    )
      return;
    gettingDistanceCalculate(data)
      .then((r) => {
        // just calculation pending
        console.log(r.data);
        let km = milesToKm(r.data.distance);
        let finalKM = 0;
        let cost = 0;
        if (
          vehicle?.wdwa_dka !== null &&
          vehicle?.wdwa_dka !== undefined &&
          vehicle?.wdwa_dka !== ""
        ) {
          finalKM =( km * 2 )- vehicle?.wdwa_dka;
          if(finalKM > 0){
            cost = finalKM * vehicle?.wdwa_dkr;
            console.log(finalKM);
            console.log(cost);
            setOutsideCost(cost);
          }

        }
        console.log(km);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [outsideDestinationName]);
  const milesToKm = (miles) => {
    // return in 2 after .
    return (miles * 1.609344).toFixed(2);
  };

  useEffect(() => {
    if (outsideCost > 0) {
      priceCalculate(outsideCost);
    }
  }, [outsideCost]);

  const priceCalculate = async (valueinUSD) => {
    try {
      const ghcGet = await axios.get(
        "https://latest.currency-api.pages.dev/v1/currencies/usd.json"
      );

      const ghcRate = convertToDecimal(ghcGet.data?.usd?.ghs ?? 0);
      if (ghcRate === 0) throw new Error("Invalid exchange rate received");

      const convertedValue = valueinUSD * ghcRate;
      // convertedValue.toFixed(2);

      setOutsideCostGHC(convertToDecimal(convertedValue));

      return convertedValue;
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      return 0;
    }
  };

  useEffect(() => {
    console.log(licenseFile);
    //   get file path
    if (licenseFile === null || licenseFile === undefined) return;
    const reader = new FileReader();
    reader.readAsDataURL(licenseFile);
    reader.onload = () => {
      setLicenseFileBase64(reader.result);
    };
  }, [licenseFile]);
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  return (
    <>
      {book_state?.serviceType.value === "self-drive" && (
        <div className="flex flex-col gap-16 mb-16">
          <Card className="p-6">
            <LicenceComponent />
          </Card>
        </div>
      )}
      <Card className="p-6">
        <div className="w-full flex items-center md:items-start flex-col md:flex-row gap-6">
          <p className="text-2xl font-bold">Number of Persons</p>
          <div className="flex gap-3 items-center">
            <TextField
              label="Persons"
              onChange={(e) => setTravelers(e.target.value)}
              type={"number"}
              defaultValue={1}
              min={1}
              value={travelers}
            />
          </div>
        </div>
        <div className="w-full mt-4 grid grid-cols-1 gap-8">
          <div className="flex gap-3 cursor-pointer items-center mt-12">
            <input
              type="checkbox"
              id="airport_pickup"
              className="remember_check cursor-pointer"
              onChange={(e) => setAirportPickup(e.target.checked)}
              checked={airportPickup}
              value={airportPickup}
            />
            <label
              className="font-text select-none cursor-pointer"
              htmlFor="airport_pickup"
            >
              Airport pickup details
            </label>
          </div>
          {airportPickup && (
            <>
              <div className="col-span-1">
                <p className="text-2xl font-semibold">
                  Airport Pickup Service Details, ${vehicle?.airport_rate}
                </p>
              </div>
              <div className="col-span-1 grid grid-cols-1 md:grid-cols-2 gap-12">
                <div className="col-span-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        onChange={(value) => setArrivalDate(value)}
                        label="Arrival Date"
                        className="w-full"
                        value={arrivalDate}
                        minDate={dayjs(new Date(book_state?.startdate))}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="col-span-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        onChange={(value) => setArrivalTime(value)}
                        label="Arrival Time"
                        className="w-full"
                        value={arrivalTime}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="col-span-1 grid grid-cols-1 md:grid-cols-2 gap-12">
                <TextField
                  label="Flight No"
                  onChange={(e) => setFlightNo(e.target.value)}
                  value={flightNo}
                />
              </div>
            </>
          )}
        </div>
      </Card>
      <Card className="p-6 mt-6 !overflow-visible">
        <input
          type="checkbox"
          id="destination_outside"
          className="remember_check cursor-pointer"
          onChange={(e) => setOutsideDestination(e.target.checked)}
          checked={outsideDestination}
          value={outsideDestination}
        />
        <label
          className="font-text select-none cursor-pointer ml-2"
          htmlFor="destination_outside"
        >
          Destination Outside {book_state?.pickupLocation.value}
        </label>
        {outsideDestination && (
          <div>
            <div className="w-full grid grid-cols-2 gap-10">
              <div className="col-span-1 flex flex-col gap-6">
                <p className="font-bold text-2xl">
                  Enter Destination Outside {book_state?.pickupLocation.value}
                </p>
                <Autocomplete
                  onPlaceSelected={(place) => {
                    console.log(place);
                    setOutsideDestinationName(place);
                  }}
                  className="w-full h-12 rounded-sm bg-[#F6F6F6] p-2 flex items-center justify-start gap-4"
                  apiKey={GOOGLE_API_KEY}
                  defaultValue={outsideDestinationName?.formatted_address}
                  options={{
                    types: ["(cities)"], // Only show cities
                    componentRestrictions: { country: "GH" }, // Restrict to Ghana
                  }}
                />
              </div>
              <div className="col-span-1 flex flex-col items-center justify-start gap-6">
                <p className="font-bold text-2xl">Estimated Extra Cost</p>
                <Card className="px-16 py-6">
                  <p className="font-bold text-2xl">
                    ${Number(outsideCost).toFixed(2)}
                  </p>
                </Card>
              </div>
            </div>
          </div>
        )}
      </Card>
      {book_state?.serviceType.value === "self-drive" ? (
        <div className="flex gap-3 cursor-pointer items-center mt-8">
          <input
            type="checkbox"
            id="remember_account"
            className="remember_check cursor-pointer"
            onChange={(e) => setTestify(e.target.checked)}
            checked={testify}
          />
          <label
            className="font-text select-none cursor-pointer"
            htmlFor="remember_account"
          >
            I testify that I am 25 years of age and I have at least 5 years
            driving experience
          </label>
        </div>
      ) : (
        <></>
      )}
      {/* Confirmation Modal */}
      <Modal open={showConfirmation} onClose={() => setShowConfirmation(false)}>
        <Box
          sx={{
            width: 400,
            backgroundColor: "white",
            p: 4,
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Confirm Your Booking Details
          </Typography>
          <Typography>
            <strong>Airport Pickup:</strong> {airportPickup ? "Yes" : "No"}
          </Typography>
          {airportPickup && (
            <>
              <Typography>
                <strong>Arrival Date:</strong>{" "}
                {arrivalDate?.format("YYYY-MM-DD")}
              </Typography>
              <Typography>
                <strong>Arrival Time:</strong> {arrivalTime?.format("HH:mm")}
              </Typography>
              <Typography>
                <strong>Flight No:</strong> {flightNo}
              </Typography>
            </>
          )}
          <Typography>
            <strong>Outside Destination:</strong>{" "}
            {outsideDestination
              ? outsideDestinationName?.formatted_address
              : "No"}
          </Typography>
          <Typography>
            <strong>Number of Travelers:</strong> {travelers}
          </Typography>
          <Typography>
            <strong>Extra Cost:</strong>{" "}
            {outsideDestination ? `$${Number(outsideCost).toFixed(2)}` : "$0"}
          </Typography>

          <Box mt={2} display="flex" justifyContent="space-between">
            <button
              className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-3 rounded-lg shadow-md"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </button>
            <button
              onClick={confirmAndContinue}
              className="bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-lg shadow-md"
            // onClick={beforeContinue}
            >
              Confirm & Continue
            </button>
          </Box>
        </Box>
      </Modal>
      <div className="w-full mt-12">
        <RButton isradius={true} isfullwidth={true} onClick={beforeContinue}>
          <span className="flex w-full justify-center items-center gap-2 px-10">
            Continue <BsArrowUpRight className="font-bold" />
          </span>
        </RButton>
      </div>
    </>
  );
};

export default Details;
