import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ButtonDatePicker from "../../components/ButtonDatePicker";
import ButtonSelect from "../../components/ButtonSelect";
import ButtonTimePicker from "../../components/ButtonTimePicker";
import RButton from "../../../src/components/RButton";
import moment from "moment/moment";
import { TbClockShare } from "react-icons/tb";
import { BsArrowUpRight, BsCalendar4Event } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BookingModal from "../../../src/components/BookingModal";
import { SET_BOOKING_STEP_0 } from "../../redux/type";
import { Select } from "antd";
import HeroSlider from "../../components/HeroSlider";
import { toast } from "react-toastify";

export default function HeroSection() {
  const book_state = useSelector((state) => state.book);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [isDaily, setIsDaily] = useState(true);
  const [startdate, setStartDate] = useState(book_state?.startdate);
  const [starttime, setStartTime] = useState(book_state?.starttime);
  const [pickupLocation, setPickupLocation] = useState(
    book_state?.pickupLocation
  );
  const [dropoffLocation, setDropoffLocation] = useState(
    book_state?.dropoffLocation
  );
  const [selectedOption, setSelectedOption] = useState(book_state?.serviceType);
  const [selectedHours, setSelectedHours] = useState(book_state?.serviceHourly);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const [checkdraweropenOnce, setCheckDrawerOpenOnce] = useState(false);

  const sliderRef = useRef(null);

  // const [currentSlide, setCurrentSlide] = useState(0);

  const BookNowButtonHandler = () => {
    if (!checkdraweropenOnce) {
      toast.error("Please select an option By Clicking the + Icon!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const payload = {
      startdate,
      starttime,
      pickupLocation,
      dropoffLocation,
      serviceType: selectedOption,
    };
    console.log("Dispatching:", payload);
    dispatch({ type: SET_BOOKING_STEP_0, payload });
    navigation("/booking");
    // setBookingModalVisible(true);
  };
  // Function to go to the previous slide
  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Function to go to the next slide
  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const OpenExtraMenu = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setCheckDrawerOpenOnce(true);
  };

  // useEffect(() => {
  //   if (!checkdraweropenOnce) {
  //     setIsDrawerOpen(true);
  //   }
  // }, [checkdraweropenOnce]);

  const BookNow = () => {
    return (
      <div className="relative">
        <div className="w-[90vw] max-w-6xl mx-auto rounded-md shadow-2xl overflow-hidden z-10 relative">
          <div className="flex absolute left-0 top-0 md:-top-6 z-20">
            <button
              className={` p-4 pt-8 text-sm font-semibold text-center rounded-tl-lg  ${
                isDaily ? "bg-[#024273] text-white" : "bg-[#FDEEEC] text-black"
              }`}
              onClick={() => {
                setIsDaily(true);
                setIsDrawerOpen(true);
              }}
            >
              Daily Rental
            </button>
            <button
              className={` p-4 pt-8 text-sm font-semibold text-center rounded-tr-lg  ${
                !isDaily ? "bg-[#024273] text-white" : "bg-[#FDEEEC] text-black"
              }`}
              onClick={() => {
                setIsDaily(false);
                setIsDrawerOpen(true);
              }}
            >
              Hourly Rental
            </button>
          </div>
          <div className="p-3 space-y-6 mt-10 bg-white  rounded-bl-lg rounded-tr-lg rounded-br-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-1 ">
              <ButtonDatePicker
                onDateChange={(value) => setStartDate(value)}
                value={startdate}
                dvalue={startdate}
                icon={<BsCalendar4Event />}
                title={"Date"}
              />
              <ButtonTimePicker
                onTimeChange={(value) => {
                  setStartTime(moment(value?.format("YYYY-MM-DD HH:mm:ss")));
                }}
                value={starttime}
                icon={<TbClockShare />}
                defaultValue={moment(starttime).format("HH:mm:ss")}
                title={"Time"}
              />
              <ButtonSelect
                label={"image/home/from.svg"}
                options={null}
                value={pickupLocation}
                onOptionChange={(value) => setPickupLocation(value)}
                title={"From"}
              />
              <ButtonSelect
                label={"image/home/to.svg"}
                options={null}
                value={dropoffLocation}
                onOptionChange={(value) => setDropoffLocation(value)}
                title={"To"}
              />
              <RButton isradius={true} onClick={BookNowButtonHandler}>
                <span className="flex items-center justify-center gap-2  w-full text-lg md:px-8 font-semibold ">
                  Book Now
                  <BsArrowUpRight />
                </span>
              </RButton>
            </div>
          </div>
        </div>
        <div
          className={`w-[80vw] max-w-5xl mx-auto bg-white rounded-b-md shadow-2xl overflow-hidden transition-all duration-300 ease-in-out ${
            isDrawerOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
          }`}
          style={{
            transform: isDrawerOpen ? "translateY(0)" : "translateY(-100%)",
            zIndex: 5,
          }}
        >
          <div className="p-6 md:mx-32 space-y-6">
            <Select
              className="w-full h-12 text-lg border-2 border-blue-400 rounded-lg text-center"
              dropdownStyle={{ textAlign: "center" }}
              options={[
                { label: "Self-Drive", value: "self-drive" },
                { label: "Chauffeur Drive", value: "chauffeur-driven" },
              ]}
              value={selectedOption?.value}
              onChange={(value, option) => setSelectedOption(option)}
              placeholder="Select option"
            />

            {!isDaily && (
              <Select
                className="w-full h-12 text-lg border-2 border-blue-400 rounded-lg text-center"
                dropdownStyle={{ textAlign: "center" }}
                options={[
                  { value: "1", label: "1 Hr" },
                  { value: "2", label: "2 Hr" },
                  { value: "3", label: "3 Hr" },
                  { value: "4", label: "4 Hr" },
                  { value: "5", label: "5 Hr" },
                ]}
                // value={selectedHours}
                // onChange={(value) => setSelectedHours(value)}
                // placeholder="Select hours"
                value={selectedHours?.value}
                onChange={(value, option) => setSelectedHours(option)} // Capture full option
                placeholder="Select hours"
              />
            )}
          </div>
        </div>
        {/* <button
          className="absolute   -bottom-3 left-1/2 transform -translate-x-1/2 translate-y-1/2 rounded-full  shadow-md p-1 z-30"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          {isDrawerOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          )}
        </button> */}
        <button
          className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 translate-y-1/2 rounded-full bg-white shadow-xl p-3 z-30 hover:bg-blue-50 transition-colors duration-200"
          onClick={OpenExtraMenu}
        >
          {isDrawerOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-8 h-8 text-blue-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-8 h-8 text-blue-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          )}
        </button>
      </div>
    );
  };

  return (
    <div className="bg-red relative pb-24">
      <div>
        <HeroSlider />
      </div>
      <div className="md:hidden absolute flex items-center justify-center shadow-lg rounded-lg w-full">
        <BookNow />
      </div>
      <div className="hidden absolute bottom-[30px] md:flex items-center justify-center w-full">
        <div className="absolute bottom-20 left-0 right-0 flex items-center justify-center">
          <BookNow />
          {bookingModalVisible && (
            <BookingModal
              visible={bookingModalVisible}
              setVisible={setBookingModalVisible}
              onClose={() => setBookingModalVisible(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
