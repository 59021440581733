import React from "react";
import Carousel from "./Carousel";
import Card from "./Card";
import "./index.css";
import { Link } from "react-router-dom";

const services = [
  {
    id: 1,
    image: "thumbnail1.png",
    title: "Chauffeur Driven Service",
    text: "Our most popular car rental service which includes the vehicle of your choice",
    link: "service_chauffaeur",
  },
  {
    id: 2,
    image: "thumbnail2.png",
    title: "SelfDrive Options",
    text: "This service is the self-drive car rental option of YOKS.",
    link: "service_selfdrive",
  },
  {
    id: 3,
    image: "thumbnail3.png",
    title: "Corporate Fleet Management",
    text: "Over the years we have built the technical capacity and managerial competencies in fleet management",
    link: "service_corperatefleet",
  },
  {
    id: 4,
    image: "thumbnail4.png",
    title: "YOKS Shuttle Services",
    text: "Whether you need to be picked up from the airport, home, school, your workers and safely transported",
    link: "service_shuttle",
  },
  {
    id: 5,
    image: "thumbnail5.svg",
    title: "Driver Personnel Outsourcing",
    text: "we have a talented pool of drivers to staff any position for short and long term.",
    link: "service_driverpersonnel",
  },
  {
    id: 6,
    image: "thumbnail6.svg",
    title: "YOKS Special Events ticketing",
    text: "Ay YOKS we host events, as well as serve as special events ticket portal. for all your event needs.",
    link: "service_eventticketing",
  },
  {
    id: 7,
    image: "thumbnail7.svg",
    title: "Security Escort",
    text: "our high value clients who may need a little extra security are provided for based on formal request .",
    link: "service_securityescort",
  },
  {
    id: 8,
    image: "thumbnail8.svg",
    title: "YOKS Tours Packages",
    text: "We have great packages tailored to suit your travel and tourism needs throughout the country.",
    link: "service_tourpackages",
  },
  {
    id: 9,
    image: "thumbnail9.svg",
    title: "Meet and Greet/Visa Arrangement",
    text: "Processing travel documentation and immigration forms, assist personnel with check-In arrangements at the Airport",
    link: "service_meet",
  },
];
const OurServices = () => {
  return (
    <div className="w-[90%] container mx-auto max-w-7xl my-12 ">
      <div className="flex flex-col md:flex-row justify-between mx-12 md:mx-0 items-center">
        <h1 className="text-3xl md:text-4xl font-medium">Our Services</h1>
        <Link
          to="/services"
          className="text-base text-[var(--main-color2)] font-bold"
        >
          <p className=" flex items-end gap-2">
            More Services{" "}
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mb-1"
            >
              <path
                d="M12.3033 10.7794C12.0735 10.7794 11.865 10.6863 11.7141 10.5355C11.5633 10.3846 11.4701 10.1761 11.4701 9.94629L11.4701 3.70843L2.28603 12.8925C1.96017 13.2184 1.43338 13.2184 1.10752 12.8925C0.781663 12.5666 0.781663 12.0399 1.10752 11.714L10.2916 2.52992L4.05371 2.52989C3.59291 2.52989 3.22054 2.15751 3.22054 1.69671C3.22054 1.23592 3.593 0.863454 4.0538 0.863454L12.3034 0.863449C12.7642 0.863449 13.1365 1.2358 13.1365 1.6966L13.1365 9.94617C13.1365 10.407 12.7641 10.7794 12.3033 10.7794Z"
                fill="#181A1F"
              />
            </svg>
          </p>
        </Link>
      </div>
      <Carousel className="my-12 gap-2">
        {services.map((service) => (
          <Link to={`/service_detail?name=${service.link}`}>
            <div
              className="relative aspect-square isolate flex flex-col justify-end overflow-hidden rounded-md md:mx-2 px-8 pb-12 pt-40 mx-auto mt-12 shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl"
              key={service.id}
            >
              <img
                className="absolute inset-0 h-full w-full object-cover object-center   transition duration-300 ease-in-out transform group-hover:scale-110"
                src={`image/services/${service.image}`}
                alt={service.title}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
              <h3 className="absolute bottom-4 left-4 text-md text-white">
                {service.title}
              </h3>
            </div>
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default OurServices;
