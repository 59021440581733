/* eslint-disable react-hooks/exhaustive-deps */
// React imports
// import { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// // Third Party libraries
// import { Modal, Tabs } from "antd";
// import styled from "styled-components";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";

// const { TabPane } = Tabs;
// const BModal = styled(Modal)`
//   & > .ant-modal-content {
//     padding: 0;
//     button {
//       color: white;
//       top: 0px;
//       right: 0px;
//     }
//     .ant-modal-header {
//       background-color: #024273;
//       padding: 15px;
//       border-radius: 0;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       margin-bottom: 0;
//     }
//     .ant-modal-body {
//       padding-block: 20px;
//     }
//     .ant-modal-footer {
//       display: none;
//     }
//   }
// `;

// const BTabs = styled(Tabs)`
//   div.ant-tabs-nav,
//   div.ant-tabs-content-holder {
//     padding-inline: 20px;
//   }
//   & div.ant-tabs-tab {
//     &.ant-tabs-tab-active > .ant-tabs-tab-btn {
//       color: var(--text-color);
//     }
//     .ant-tabs-tab-btn {
//       color: #faeaea;
//     }
//     .ant-tabs-tab-btn:focus {
//       color: var(--text-color);
//     }
//   }
//   .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
//     background: #024273;
//   }
// `;

const DevelopmentModal = ({ visible, setVisible }) => {
  const handleCancel = () => {
    console.log("dddddd");
    setVisible(false);
  };
  // useEffect(() => {
  //   console.log("Modal visibility changed:", visible);
  // }, [visible]);

  return (
    // <BModal
    //   className="mt-[100px]"
    //   title={
    //     <div className="flex items-center w-full">
    //       <p className="font-bold text-xl text-white">Notice</p>
    //     </div>
    //   }
    //   width={"50%"}
    //   open={visible}
    //   onCancel={handleCancel}
    // >
    //   {/*<Autocomplete*/}
    //   {/*    onPlaceSelected={(place) => console.log(place)}*/}
    //   {/*    className="w-full h-12 rounded-sm bg-[#F6F6F6] p-2 flex items-center justify-start gap-4"*/}
    //   {/*/>*/}
    //   <div className="mt-[-20px] shadow-lg p-5 absolute bg-white rounded-2xl z-50 w-[100%] sm:w-[110%] sm:ml-[-5%] flex justify-evenly items-center flex-col md:flex-row">
    //     <p className="text-[32px]">This website is under development.</p>
    //     <div className="flex items-center flex-col sm:flex-row">

    //     </div>
    //   </div>
    //   {/*className={`sm:mt-0 mt-${headerHeight + 10 + 'px'}`}*/}

    // </BModal>

    visible && (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="w-full max-w-5xl bg-white rounded-3xl overflow-hidden shadow-xl relative">
          {/* Close Button */}
          <button
            onClick={handleCancel}
            className="absolute top-4 right-4 z-10 p-2 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
          >
            <IoMdClose className="w-6 h-6 text-white" />
          </button>

          <div className="flex flex-col md:flex-row">
            {/* Left content */}
            <div className="bg-[#003B71] p-8 md:p-12 text-white md:w-1/2">
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Site Under Construction
              </h1>
              <p className="text-lg mb-8">
                Our website is currently under construction, however contact us
                if you need any service.
              </p>

              <div className="space-y-4">
                <h2 className="text-2xl font-semibold mb-4">Contact Us:</h2>
                <p className="flex flex-col gap-1">
                  <span>
                    Email:{" "}
                    <a
                      href="mailto:info@yoksghana.com"
                      className="text-blue-500 hover:underline"
                    >
                      info@yoksghana.com
                    </a>
                  </span>
                  <span>
                    Phone:{" "}
                    <a
                      href="tel:+233540614545"
                      className="text-blue-500 hover:underline"
                    >
                      +233 540 614 545
                    </a>
                  </span>
                  <span>
                    Phone:{" "}
                    <a
                      href="tel:+233531046454"
                      className="text-blue-500 hover:underline"
                    >
                      +233 531 046 454
                    </a>
                  </span>
                </p>
              </div>
            </div>

            {/* Right content */}
            <div className="relative md:w-1/2">
              <img
                src="/image/home/hero.jpg"
                alt="Car door handle"
                className="w-full h-full object-cover"
              />
              <img
                src="header_logo.png"
                alt="YOKS Logo"
                className="absolute bottom-4 right-4 w-24 h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DevelopmentModal;
