import { Card } from "@mui/material";
import { TbCalendarStar } from "react-icons/tb";
import { getDashboardAPI } from "../../redux/actions/myaccountAction";
import { useDispatch } from "react-redux";
import { getCookie } from "../../utils/constants";
import { useEffect, useState } from "react";
import BookingCard from "./BookinCard/BookingCard";
import { SET_LOADING } from "../../redux/type";
import { MdPaid } from "react-icons/md";
import { AiOutlineFileDone } from "react-icons/ai";
import { AiFillFileAdd } from "react-icons/ai";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState({});
  const getDashboard = () => {
    let postData = {
      api_token: getCookie("token"),
    };
    getDashboardAPI(dispatch, postData)
      .then((res) => {
        console.log(res);
        setDashboardData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getDashboard();
  }, []);
  const convertToDecimal = (value) => {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0"
    )
      return 0;
    console.log(typeof value);
    console.log(parseInt(value, 2));
    if (typeof value === "string") {
      value = parseFloat(value.replace(/,/g, ".")).toFixed(2);
    } else if (typeof value === "number") {
      // return with 2 decimal
      value = parseFloat(value.toFixed(2));
    }
    return value;
  };
  return (
    <>
      <div className="container w-full">
        <div className="w-full py-6 flex flex-col gap-2">
          <p className="font-bold text-xl">Dashboard</p>
          <p className="font-text text-sm">Dashboard</p>
        </div>
        <div className="w-full grid grid-cols-1 gap-6">
          <div className="col-span-1 grid grid-cols-4 gap-8">
            <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1">
              <Card className="p-8 w-full flex items-center justify-center gap-8">
                <p className="rounded-xl bg-gray-200 text-4xl p-2 flex-grow-0">
                  <MdPaid />
                </p>
                <div className="w-full">
                  <p className="font-text font-bold">Total Paid Amount</p>
                  <p className="font-text font-bold text-3xl">
                    {convertToDecimal(dashboardData?.totalPaidAmount)}
                  </p>
                </div>
              </Card>
            </div>
            <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1">
              <Card className="p-8 w-full flex items-center justify-center gap-8">
                <p className="rounded-xl bg-gray-200 text-4xl p-3 flex-grow-0">
                  <AiFillFileAdd />
                </p>
                <div className="w-full">
                  <p className="font-text font-bold">Total Booking</p>
                  <p className="font-text font-bold text-3xl">
                    {dashboardData?.totalBooking}
                  </p>
                </div>
              </Card>
            </div>
            <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1">
              <Card className="p-8 w-full flex items-center justify-center gap-8">
                <p className="rounded-xl bg-gray-200 text-4xl p-3 flex-grow-0">
                  <AiOutlineFileDone />
                </p>
                <div className="w-full">
                  <p className="font-text font-bold">Completed Booking</p>
                  <p className="font-text font-bold text-3xl">
                    {dashboardData?.completedBooking}
                  </p>
                </div>
              </Card>
            </div>
            <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1">
              <Card className="p-8 w-full flex items-center justify-center gap-8">
                <p className="rounded-xl bg-gray-200 text-4xl p-3 flex-grow-0">
                  <TbCalendarStar />
                </p>
                <div className="w-full">
                  <p className="font-text font-bold">Active Booking</p>
                  <p className="font-text font-bold text-3xl">
                    {dashboardData?.activeBooking}
                  </p>
                </div>
              </Card>
            </div>
          </div>
          <div className="col-span-1"></div>
        </div>
        <Card className="w-full p-6 flex flex-col gap-6">
          <div className="w-full flex justify-between items-center">
            <p className="font-bold text-xl">Booking History</p>
          </div>
          <BookingCard />
        </Card>
      </div>{" "}
    </>
  );
};

export default Dashboard;
