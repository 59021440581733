import React, { useEffect } from "react";
import { usePaystackPayment } from "react-paystack";
import { PAY_STACK_PK_KEY } from "../../utils/config";
import { toast } from "react-toastify";
import { BsArrowUpRight } from "react-icons/bs";
import RButton from "../../components/RButton";
const ChargePayment = ({
  email,
  price,
  charge,
  onPaymentSuccess,
  showButton = true,
}) => {
  const realPrice = () => {
    if (price === 0 || price === null || price === undefined || price === "")
      return 0;
    return parseFloat(price * 100).toFixed(2);
  };
  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: realPrice(),
    publicKey: PAY_STACK_PK_KEY,
    currency: "GHS",
    ref: "" + Math.floor(Math.random() * 1000000000 + 1),
  };
  const initializePayment = usePaystackPayment(config);
  const onSuccess = (reference) => {
    onPaymentSuccess(reference);
  };
  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
    toast.warning("Payment Cancelled, please try again", { autoClose: 5000 });
    if (!showButton) {
      onPaymentSuccess(null);
    }
  };
  useEffect(() => {
    // console.log("Payment side :", price);
    console.log(charge);
    if (charge) {
      setTimeout(() => {
        handleConfirm();
      }, 200);
    }
  }, [charge]);
  const handleConfirm = () => {
    initializePayment(onSuccess, onClose);
  };
  return (
    <div>
      {charge && showButton && (
        <RButton isradius={true} isfullwidth={true} onClick={handleConfirm}>
          <span className="flex w-full justify-center items-center gap-2 px-10">
            Payment <BsArrowUpRight className="font-bold" />
          </span>
        </RButton>
      )}
    </div>
  );
};

export default ChargePayment;
